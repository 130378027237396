import { render, staticRenderFns } from "./TopBar.vue?vue&type=template&id=7e514eb8&scoped=true&"
import script from "./TopBar.vue?vue&type=script&lang=js&"
export * from "./TopBar.vue?vue&type=script&lang=js&"
import style0 from "./TopBar.vue?vue&type=style&index=0&id=7e514eb8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e514eb8",
  null
  
)


    import installComponents from "!/home/ubuntu/URCHIN-Server/client/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BNavbarBrand, BNavbarToggle, BNavItem, BNavbarNav, BIconPersonSquare, BDropdownItem, BNavItemDropdown, BCollapse, BNavbar} from 'bootstrap-vue'
    installComponents(component, {BNavbarBrand, BNavbarToggle, BNavItem, BNavbarNav, BIconPersonSquare, BDropdownItem, BNavItemDropdown, BCollapse, BNavbar})
    

export default component.exports