import Vue from 'vue'
import Inputmask from 'inputmask/lib/inputmask'
import { postalMask, phoneMask } from '@/variables'

/* eslint-disable no-unused-vars */
const Plugin = {
  install: function (Vue, options) {
    Inputmask.extendDefaults({ autoUnmask: true })
    Vue.directive('mask', {
      bind: function (el, binding) {
        Inputmask(binding.value).mask(el)
      }
    })
  }
}
/* eslint-enable no-unused-vars */

Vue.use(Plugin)

const format = function (value, mask) {
  if (!value) return ''
  value = value.toString()
  return Inputmask.format(value, { alias: mask })
}

Vue.mixin({
  methods: {
    format: format,
    postalFormat: function (value) {
      return format(value, postalMask)
    },
    phoneFormat: function (value) {
      return format(value, phoneMask)
    }
  }
})

export default Plugin
