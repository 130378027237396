import Vue from 'vue'
import VueRouter from 'vue-router'

// import { roles } from '@/variables'
import store from '@/store'

const Home = () => import(/* webpackChunkName: "group-login" */ '@/views/auth/Home.vue')
const Login = () => import(/* webpackChunkName: "group-login" */ '@/views/auth/Login')
const SetPassword = () => import(/* webpackChunkName: "group-login" */ '@/views/auth/SetPassword')
const LocationMap = () => import(/* webpackChunkName: "group-main" */ '@/views/map/LocationMap')

const Account = () => import(/* webpackChunkName: "group-main" */ '@/views/Account')
const Reports = () => import(/* webpackChunkName: "group-main" */ '@/views/reports/Reports')
const StatusReport = () => import(/* webpackChunkName: "group-main" */ '@/views/reports/StatusReport')
const RetiredLotsReport = () => import(/* webpackChunkName: "group-main" */ '@/views/reports/RetiredLotsReport')
const ActiveLotsReport = () => import(/* webpackChunkName: "group-main" */ '@/views/reports/ActiveLotsReport')
const RetiredContractsReport = () => import(/* webpackChunkName: "group-main" */ '@/views/reports/RetiredContractsReport')
const LotReport = () => import(/* webpackChunkName: "group-main" */ '@/views/reports/LotReport')
const AnimalReport = () => import(/* webpackChunkName: "group-main" */ '@/views/reports/AnimalReport')
const TagEventReport = () => import(/* webpackChunkName: "group-main" */ '@/views/reports/TagEventReport')
const Shipped = () => import(/* webpackChunkName: "group-main-staff" */ '@/views/reports/Shipped')
const ReadyForValidation = () => import(/* webpackChunkName: "group-main-staff" */ '@/views/reports/ReadyForValidation')
const LoadedProcessing = () => import(/* webpackChunkName: "group-main-staff" */ '@/views/reports/LoadedProcessing')
const EventsListReport = () => import(/* webpackChunkName: "group-main-staff" */ '@/views/reports/EventsListReport')

const CustomReportForm = () => import(/* webpackChunkName: "group-main" */ '@/views/reports/CustomReportForm')
const CustomReport = () => import(/* webpackChunkName: "group-main" */ '@/views/reports/CustomReport')

const LocationReport = () => import(/* webpackChunkName: "group-main" */ '@/views/reports/LocationReport')
const Gates = () => import(/* webpackChunkName: "group-main" */ '@/views/gates/Gates')
const GateTemp = () => import(/* webpackChunkName: "group-main" */ '@/views/gates/GateTemp')
const GateReport = () => import(/* webpackChunkName: "group-main" */ '@/views/gates/GateReport')
const Locations = () => import(/* webpackChunkName: "group-main" */ '@/views/locations/Locations')
const ScanTable = () => import(/* webpackChunkName: "group-main" */ '@/views/reports/ScanTable')
const SystemReport = () => import(/* webpackChunkName: "group-main" */ '@/views/reports/SystemReport')
const SummaryReport = () => import(/* webpackChunkName: "group-main" */ '@/views/reports/SummaryReport')
const MemberInductionsReport = () => import(/* webpackChunkName: "group-main" */ '@/views/reports/MemberInductionsReport')
const DeadAnimalsReport = () => import(/* webpackChunkName: "group-main" */ '@/views/reports/DeadAnimalsReport')
const CombineLots = () => import(/* webpackChunkName: "group-main" */ '@/views/reports/CombineLots')
const AnimalLookup = () => import(/* webpackChunkName: "group-main" */ '@/views/reports/AnimalLookup')

// https://router.vuejs.org/guide/advanced/lazy-loading.html#grouping-components-in-the-same-chunk
// Pros: faster load upfront, if never clicked it's saved data
// Cons: more number of calls, but if people are using often it's in cache anyway
const Admin = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/Admin')
const UserList = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/UserList')
const MemberList = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/MemberList')
const LocationList = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/LocationList')
const GateList = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/GateList')
const LotList = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/LotList')
const AnimalList = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AnimalList')
const TagList = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/TagList')
const AppSettings = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AppSettings')
const FunderList = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/FunderList')

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login', name: 'Home', component: Home,
    children: [
      { path: '/login', name: 'Login', component: Login },
      { path: '/activate', name: 'ActivateAccount', component: SetPassword },
      { path: '/verify', name: 'VerifyEmail', component: SetPassword },
      { path: '/reset', name: 'ResetPassword', component: SetPassword }
    ]
  },
  // { path: '/about', name: 'About', component: () => import('@/views/About.vue') },
  { path: '/map', name: 'Map', component: LocationMap, meta: { auth: () => store.getters.hasBasicAccess } },
  { path: '/account', name: 'Account', component: Account },
  { path: '/reports', redirect: '/reports/status', name: 'Reports', component: Reports,
    children: [
      { path: 'status', name: 'StatusReport', component: StatusReport, meta: { auth: () => store.getters.hasBasicAccess }, props: true },
      { path: 'retired-lots', name: 'RetiredLotsReport', component: RetiredLotsReport, props: true, meta: { auth: () => store.getters.isStaff } },
      { path: 'active-lots', name: 'ActiveLotsReport', component: ActiveLotsReport, props: true, meta: { auth: () => store.getters.isStaff } },
      { path: 'retired-contracts', name: 'RetiredContractsReport', component: RetiredContractsReport, props: true, meta: { auth: () => store.getters.isStaff } },
      { path: 'shipped', name: 'Shipped', component: Shipped, props: true, meta: { auth: () => store.getters.hasBasicAccess, eventType: 'LOA' } },
      { path: 'shipped/:eventId/', name: 'LoadedProcessing', component: LoadedProcessing, props: true, meta: { auth: () => store.getters.isStaff } },
      { path: 'for-validation', name: 'ReadyForValidation', component: ReadyForValidation, props: true, meta: { auth: () => store.getters.isStaff } },
      { path: 'inductions', name: 'InductionEvents', component: EventsListReport, props: true, meta: { auth: () => store.getters.hasBasicAccess, eventType: 'IND' } },
      { path: 'validations', name: 'ValidationEvents', component: EventsListReport, props: true, meta: { auth: () => store.getters.isStaff, eventType: 'VAL' } },
      { path: 'inventory', name: 'InventoryEvents', component: EventsListReport, props: true, meta: { auth: () => store.getters.hasBasicAccess, eventType: 'INV' } },
      { path: 'pre-loading', name: 'PreLoadingEvents', component: EventsListReport, props: true, meta: { auth: () => store.getters.hasBasicAccess, eventType: 'PRE' } },
      { path: 'exceptions', name: 'ExceptionEvents', component: EventsListReport, props: true, meta: { auth: () => store.getters.hasBasicAccess, eventType: 'EXC' } },
      { path: 'transfers', name: 'TransferEvents', component: EventsListReport, props: true, meta: { auth: () => store.getters.hasBasicAccess, eventType: 'TRA' } },
      { path: 'custom/:reportId', name: 'CustomReport', component: CustomReport, meta: { auth: () => store.getters.hasBasicAccess }, props: true },
      { path: 'locations/:locationId', name:'LocationReport', component: LocationReport, meta: { auth: () => store.getters.hasBasicAccess }, props: true },
      { path: 'locations/:locationId/lots/:lotId', name: 'LotReport', component: LotReport, meta: { auth: () => store.getters.hasBasicAccess }, props: true },
      { path: 'locations/:locationId/tags/:tagId', name: 'AnimalReport', component: AnimalReport, meta: { auth: () => store.getters.hasBasicAccess }, props: true },
      { path: 'locations/:locationId/events/:eventId', name: 'TagEventReport', component: TagEventReport, meta: { auth: () => store.getters.hasBasicAccess }, props: true },
      { path: 'summaryReport', name: 'SummaryReport', component: SummaryReport, props: true, meta: { auth: () => store.getters.isStaff } },
      { path: 'memberInductionsReport', name: 'MemberInductionsReport', component: MemberInductionsReport, props: true },
      { path: 'deadAnimalsReport', name: 'DeadAnimalsReport', component: DeadAnimalsReport, props: true },
      { path: 'combineLots', name: 'CombineLots', component: CombineLots, meta: { auth: () => store.getters.isAdmin }, props: true },
      { path: 'systemReport', name: 'SystemReport', component: SystemReport, props: true, meta: { auth: () => store.getters.isStaff } },
      { path: '/gates/:gateId/gateReport', name: 'GateReport', component: GateReport, meta: { auth: () => store.getters.hasBasicAccess }, props: true },
      { path: 'animalLookup', name: 'AnimalLookup', component: AnimalLookup, props: true, meta: { auth: () => store.getters.hasBasicAccess } },
    ]
  },
  { path: '/reports/custom', name: 'CustomReportForm', component: CustomReportForm, meta: { auth: () => store.getters.hasBasicAccess } },
  { path: '/reports/members/:memberId', name: 'MemberLocations', component: Locations, meta: { auth: () => store.getters.hasBasicAccess } },
  { path: '/locations', name: 'Locations', component: Locations, meta: { auth: () => store.getters.hasBasicAccess } },
  { path: '/gates', name: 'Gates', component: Gates, meta: { auth: () => store.getters.hasBasicAccess } },
  { path: '/gates/:gateId/gateTemp', name: 'GateTemp', component: GateTemp, meta: { auth: () => store.getters.hasBasicAccess }, props: true },
  { path: '/scanTable', name: 'ScanTable', component: ScanTable, meta: { auth: () => store.getters.isScanExport || store.getters.isAdmin }, props: true },
  { path: '/admin', redirect: '/admin/members', name: 'Admin', component: Admin,
    children: [
      { path: 'members', name: 'MemberList', component: MemberList, meta: { auth: () => store.getters.isStaff }, props: true },
      { path: 'accounts', name: 'AccountList', component: UserList, meta: { auth: () => store.getters.isStaff }, props: true },
      { path: 'locations', name: 'LocationList', component: LocationList, meta: { auth: () => store.getters.isSupervisor }, props: true },
      { path: 'gates', name: 'GateList', component: GateList, meta: { auth: () => store.getters.isSupervisor }, props: true },
      { path: 'lots', name: 'LotList', component: LotList, props: true },
      { path: 'animals', name: 'AnimalList', component: AnimalList, props: true },
      { path: 'tags', name: 'UHFTagList', component: TagList, meta: { auth: () => store.getters.isSupervisor }, props: true },
      { path: 'appsettings', name: 'AppSettings', component: AppSettings, meta: { auth: () => store.getters.isSupervisor } },
      { path: 'funders', name: 'FunderList', component: FunderList, meta: { auth: () => store.getters.isMember || store.getters.isAdmin }, props: true },
    ]
  },
  { path: '/db-admin', name: 'AdminSite', beforeEnter() { window.open(`${process.env.VUE_APP_BACKEND_DOMAIN}/admin`, '_blank') }, meta: { auth: () => store.getters.isAdmin } },
  { path: '/404', name: '404', component: () => import('@/views/404') },
  { path: '*', redirect: '/404' }
]

const router = new VueRouter({
  routes
})


const unauthorized_allowed = ['Login', 'ActivateAccount', 'VerifyEmail', 'ResetPassword']

// router to redirect to login page if logged out
router.beforeEach(function (to, from, next) {
  // console.log(to, from, next)
  console.log('router.beforeEach', to.path + ' - Auth: ' + store.getters.isLoggedIn)
  if (!store.getters.isLoggedIn && !unauthorized_allowed.includes(to.name)) {
    return next({ name: 'Login', query: { redirect: to.path } })
  }

  const { auth } = to.meta
  // console.log(to.meta, auth)
  if (auth != null && !auth()) {
    return next({ name: '404' })
  }
  next()
})

export default router
