<template>
  <b-navbar class="bg-primary" type="dark" fixed="top" toggleable="md">
    <b-navbar-brand class="bg-light logo" :to="{ name: 'Login' }">
      <img src="../assets/logo.png" width="70" alt="Pincher Creek Feeders Cooperative"/>
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse" @click="navOpen = !navOpen"></b-navbar-toggle>
    <b-collapse id="nav-collapse" v-model="navOpen" is-nav>
      <b-navbar-nav small class="main-nav">
        <b-nav-item :to="{ name: 'Reports' }" v-if="hasBasicAccess">REPORTS</b-nav-item>
        <b-nav-item :to="{ name: 'Locations' }" v-if="hasBasicAccess">LOCATIONS</b-nav-item>
        <b-nav-item :to="{ name: 'Gates' }" v-if="hasBasicAccess">GATES</b-nav-item>
        <b-nav-item :to="{ name: 'Map' }" v-if="hasBasicAccess">MAP</b-nav-item>
        <b-nav-item :to="{ name: 'Admin' }" v-if="isStaff">ADMIN</b-nav-item>
        <b-nav-item :to="{ name: 'FunderList' }" v-if="isMember">ADMIN</b-nav-item>
        <b-nav-item :to="{ name: 'AdminSite' }" v-if="isAdmin">DATABASE ADMIN</b-nav-item>
        <b-nav-item :to="{ name: 'ScanTable' }" v-if="isAdmin || isScanExport">TAG SCAN EXPORT</b-nav-item>
        <b-nav-item :to="{ name: 'SummaryReport' }" v-if="isStaff">SUMMARY REPORT</b-nav-item>
        <b-nav-item :to="{ name: 'CombineLots' }" v-if="isAdmin">COMBINE LOTS</b-nav-item>
        <b-nav-item :to="{ name: 'AnimalLookup' }" v-if="hasBasicAccess">ANIMAL LOOKUP</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown right no-caret>
          <template v-slot:button-content>
            <p class="h5 m-0 text-split">
              <em>{{ user.first_name }} {{user.last_name }} </em>
              <span class="pl-2">
                <b-icon-person-square></b-icon-person-square>
              </span>
            </p>
          </template>
          <b-dropdown-item :to="{ name: 'Account' }">Account</b-dropdown-item>
          <b-dropdown-item @click="logout">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
    <div id="backdrop" v-if="navOpen" @click="navOpen = false" />
  </b-navbar>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  export default {
    name: "TopBar",
    data () {
      return {
        navOpen: false
      }
    },
    // mounted () {
    //   console.log(this.user)
    // },
    computed: {
      ...mapState(['user']),
      ...mapGetters(['isStaff', 'isSupervisor','isAdmin', 'isScanExport', 'hasBasicAccess', 'isMember'])
    },
    methods: {
      logout: function () {
        this.$store.dispatch('logout')
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "styles/custom.scss";

.logo {
  border-radius: 5px;
  padding: 2px 96px 2px 10px;
}

.main-nav {
  .nav-item {
    @media (min-width: 767px) {
      padding: 0px 15px 0px 15px;
      &:not(:last-child) {
        border-right: 1px solid map-get($theme-colors, "light");
      }
    }
    @media (max-width: 767px) {
      border-bottom: 1px solid map-get($theme-colors, "light");
    }
    a {
      // color: #2c3e50;

      &.router-link-active {
        color: #42b983;
      }
    }
  }
}

#backdrop {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
</style>
