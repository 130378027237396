"use strict"

import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import router from '@/router'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// client/.env.development for the following domain info
const _axios = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_DOMAIN + '/api',
  timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
});

// _axios.interceptors.request.use(
//   function(config) {
//     // Do something before request is sent
//     return config;
//   },
//   function(error) {
//     // Do something with request error
//     return Promise.reject(error);
//   }
// );

// interceptor to add JWT if available
_axios.interceptors.request.use(
  function(config) {
    if (store.getters.isLoggedIn) {
      config.headers.Authorization = `Bearer ${store.getters.jwt.access}`
    }
    return config
  }, error => {
    // console.log('In axios.interceptors.request.error')
    return Promise.reject(error)
  }
)
_axios.interceptors.response.use(
  response => {
    // console.log('In axios.interceptors.response.response')
    return response
  },
  error => {
    // console.log('In axios.interceptors.response.error')
    // If not authentication error OR attempting to login, let through
    if (error.response.status !== 401 || error.response.config.url == process.env.VUE_APP_BACKEND_DOMAIN + '/api/token/') {
      return Promise.reject(error)
    }

    // Logout user if token refresh didn't work
    if (error.config.url.includes('/token/refresh/')) {
      store.dispatch('logout')
      router.push({ name: 'Login' })
      return Promise.reject(error)
    }

    return new Promise((resolve, reject) => {
      // Try to refresh token
      let jwt = store.getters.jwt
      _axios
        .post('/token/refresh/', { refresh: jwt.refresh })
        .then(response => {
          jwt.access = response.data.access
          store.commit('setJwt', jwt)
          resolve(jwt)
        })
        .catch(e => {
          reject(e)
        })
      })
      .then(jwt => {
        // Redo request with new token
        const config = error.config
        config.headers['Authorization'] = `Bearer ${jwt.access}`
        return new Promise((resolve, reject) => {
          _axios.request(config).then(response => {
            resolve(response)
          }).catch(e => {
            reject(e)
          })
        })
      })
    .catch(e => {
      Promise.reject(e)
    })
  }
)

/* eslint-disable no-unused-vars */
Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    $axios: {
      get() {
        return _axios;
      }
    },
    $http: {
      get() {
        return _axios;
      }
    },
  });
};
/* eslint-enable no-unused-vars */

Vue.use(Plugin)

export default Plugin;
