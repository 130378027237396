import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import router from '@/router'
import { roles } from '@/variables'

Vue.use(Vuex)

const vuexSession = new VuexPersistence({
  storage: window.sessionStorage
})

const store = new Vuex.Store({
  state: {
    jwt: null,  // { access: '', refresh: ''}
    user: null,
    /**
     * User's custom reports list, shown in reports sidebar.
     * @type {Array}
     */
    customReports: [],
    /**
     * Global alert object, accessible from any component.
     * @type {Object}
     */
    alert: {
      message: null,
      variant: null,
      countdown: 0
    },
    /**
     * Store form here while navigating back and forth between custom report form
     * and preview.
     * @type {[type]}
     */
    customReportForm: null,
    /**
     * Used for filtering reports between two dates.
     * @type {Date}
     */
    filterDateRangeStart: null,
    filterDateRangeEnd: null,
    /**
     * Whether to use filter date ranges when making requests to backend.
     * @type {Boolean}
     */
    filterDate: true,
    //TODO: ADD DESCRIPTION OF vflag
    vflag: false,
    noteList: [],
    validationCount: 0
  },
  getters: {
    jwt: state => state.jwt,
    user: state => state.user,
    memberorg: state => state.user.usersetting.memberorg,
    customReports: state => state.customReports,
    isLoggedIn: state => state.jwt != null && state.user != null,
    isMember: state => state.user ? state.user.usersetting.role == roles.MEMBER : null,
    isPBFCStaff: state => state.user ? state.user.usersetting.pbfc_staff : null,
    isStaff: state => state.user ? state.user.usersetting.role >= roles.STAFF : null,
    isSupervisor: state => state.user ? state.user.usersetting.role >= roles.SUPERVISOR : null,
    isAdmin: state => state.user ? state.user.usersetting.role >= roles.SYSADMIN : null,
    isScanExport: state => state.user ? state.user.usersetting.role == roles.SCAN_EXPORT : null,
    hasBasicAccess: state => state.user ? state.user.usersetting.role >= roles.MEMBER : null,
    vflag: state => state.vflag,
    noteList: state => state.noteList,
    validationCount: state => state.validationCount
  },
  mutations: {  // mutations have to be synchronous
    setJwt (state, jwt) {
      state.jwt = jwt
    },
    setUser (state, user) {
      state.user = user
    },
    setCustomReports (state, list) {
      state.customReports = list
    },
    setAlert (state, alert) {
      alert.countdown = 5
      state.alert = alert
    },
    setCustomReportForm (state, details) {
      state.customReportForm = details
    },
    setFilterDateRangeStart (state, val) {
      state.filterDateRangeStart = val
    },
    setFilterDateRangeEnd (state, val) {
      state.filterDateRangeEnd = val
    },
    setFilterDate (state, val) {
      state.filterDate = val
    },
    setvFlag (state) {
      state.vflag= !state.vflag
    },
    setNoteList(state, val) {
      state.noteList = val
    },
    setvalidationCount(state, val) {
      console.log(val, 'THIS IS IN STORE OF VAL')
      state.validationCount = val
    }
  },
  actions: {
    /**
     * Log out user and push to login screen.
     * TODO: While not currently necessary, we should reset all state variables
     * when logging out, not just jwt and user.
     */
    logout ({ commit }) {
      commit('setJwt', null)
      commit('setUser', null)
      router.push({ name: 'Login' })
    }
  },
  plugins: [vuexSession.plugin]
})

Vue.mixin({
  methods: {
    setAlert: (alert) => store.commit('setAlert', alert)
  }
})

export default store
