import Vue from 'vue'
import { format as datefnsFormat } from 'date-fns'


export const roles = {
  SCAN_EXPORT: 1,
  MEMBER: 10,
  STAFF: 20,
  SUPERVISOR: 30,
  SYSADMIN: 50
}

const roleStrings = {
  1: 'Scan_Export',
  10: 'Member',
  20: 'Staff',
  30: 'Supervisor',
  50: 'SysAdmin'
}

/**
 * Helper function to get role name.
 * TODO: Just export roleStrings map like map objects below.
 * @param  {int} val - Role integer value
 * @return {String}    Role name
 */
export const roleStr = function (val) {
  return roleStrings[val]
}

/**
 * Tag event type map.
 * @type {Object}
 */
export const tagEventTypes = {
  IND: 'Induction',
  LOA: 'Shipping',
  INV: 'Inventory',
  VAL: 'Validation',
  PRE: 'Preloading',
  EXC: 'Exception',
  MAN: 'Manual Validation',
  TRI: 'Transfer In',
  TRO: 'Transfer Out',
}

/**
 * Map for gate types.
 * @type {Object}
 */
export const gateTypes = {
  IND: 'Head Gate',
  LOA: 'Shipping',
  PRE: 'Pre-Loading'
}

/**
 * Map for animal validation status.
 * @type {Object}
 */
export const validationStrings = {
  'Y': 'Yes',
  'N': 'No',
  'A': 'Assumed'
}

/**
 * Province code options for province select dropdowns.
 * @type {Object}
 */
export const provinceCodes = {
  "AB": "Alberta",
  "BC": "British Columbia",
  "MB": "Manitoba",
  "NB": "New Brunswick",
  "NL": "Newfoundland and Labrador",
  "NT": "Northwest Territories",
  "NS": "Nova Scotia",
  "NU": "Nunavut",
  "ON": "Ontario",
  "PE": "Prince Edward Island",
  "QC": "Quebec",
  "SK": "Saskatchewan",
  "YT": "Yukon Territory"
}

/**
 * Postal code format for inputmask.js
 * @type {String}
 */
export const postalMask = 'a9a 9a9'
/**
 * Phone number format for inputmask.js
 * @type {String}
 */
export const phoneMask = '(999) 999-9999'

// Mixins

/**
 * Formats a date or date string into consistent format.
 * @param  {Date or String} dateString Date or String in standard format
 * @return {String}         formatted date
 */
const dateformat = function (dateString) {
  // return dateString != null ? new Date(dateString).toLocaleString("en-CA") : ''
  return dateString != null ? datefnsFormat(new Date(dateString), 'yyyy-MM-dd HH:mm:ss') : ''
}

/**
 * Capitalizes each word in a string.
 * @param  {String} s - String to capitalize
 * @return {String}     Capitalized
 */
const capitalize = function (s) {
  return s.replace(/\w\S*/g, txt => txt[0].toUpperCase() + txt.slice(1))
}

/**
 * Removes non letters and numbers from string and ensures postal code is proper format.
 * Only supports Canadian and US postal codes.
 * https://stackoverflow.com/a/26788801
 *
 * @param  {String} postalCode
 * @return {String}            Postal code with no spaces or symbols.
 */
const postalFilter = function (postalCode) {
    if (!postalCode) {
      return null
    }
    postalCode = postalCode.toString().trim().toUpperCase().replace(/\W+/g, '')

    var ca = new RegExp("^[ABCEGHJKLMNPRSTVXY]\\d[ABCEGHJKLMNPRSTVWXYZ][ -]?\\d[ABCEGHJKLMNPRSTVWXYZ]\\d$")
    if (ca.test(postalCode)) {
      return postalCode
    }

    var us = new RegExp("^\\d{5}(-{0,1}\\d{4})?$")
    if (us.test(postalCode)) {
      return postalCode
    }

    return null
}

/**
 * Set any blank strings in form to null for database consistency.
 * Does not null nested objects.
 * @param  {Object} form - Object with keys and values
 * @return {Object}      Form with no blank strings
 */
const nullBlanks = function (form) {
  // one thing to be careful of: this directly modifies the parameter object
  for (let key in form) {
    if (form[key] === '') {
      form[key] = null
    }
  }
  return form
}

Vue.mixin({
  methods: {
    dateformat, capitalize, postalFilter, nullBlanks
  }
})
