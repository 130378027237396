<template>
  <div id="app" class="d-flex flex-column">
    <TopBar v-if="isLoggedIn"></TopBar>
    <b-alert class="alert-fixed" fade :variant="alert.variant" v-if="alert.message"
      :show="alert.countdown" dismissible @dismissed="setAlert({ variant: null, message: null , countdown: 0})">
      {{ alert.message }}
    </b-alert>
    <router-view class="flex-grow-1 d-flex" :class="{ 'main-content': isLoggedIn }"/>
  </div>
</template>

<script>
  import TopBar from "./components/TopBar";
  import { mapState, mapGetters } from 'vuex'

  export default {
    components: { TopBar },
    computed: {
      ...mapState(['alert']),
      ...mapGetters(['isLoggedIn'])
    }
  }
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  height: 100%;
  width: 100%;
}
.main-content {
  position: fixed;
  top: 50px;  // height of topbar
  max-height: calc(100% - 50px);
  width: 100%;
  height: 100%;
  // min-height: 100%;
  // background-color: red;
  // box-sizing: border-box;
  // -moz-box-sizing: border-box;
  // -webkit-box-sizing: border-box;
}
</style>
